export default {
  area: '吉利街道数智管理平台',
  objectName: '',
  //url
  hostUrl: 'https://jljd.anluoyun.cn',
  apiHttpsUrl: 'https://jljdapi.anluoyun.cn/',
  apiRequestUrl: 'https://jljdapi.anluoyun.cn/api',
  apiUploadUrl: 'https://jljdapi.anluoyun.cn/api/UploadFile/UploadImgOrFile',
  apiUploadFiles: 'https://jljdapi.anluoyun.cn/api/UploadFile/UploadImgOrFile',
  apiUploadvideo:
    'https://jljdapi.anluoyun.cn/api/UploadFile/UploadQiniuVideo',
}
